'use strict';

angular.module('client.services').factory('teksService', ['$log',
  function ($log) {

    var LEVELS = {
      DISTRICT: 'district',
      SCHOOL: 'school',
      CURRICULUM: 'curriculum'
    };

    function isDistrictReport(ctrl) {
      return ctrl.currentLevel === LEVELS.DISTRICT;
    }

    function isSchoolReport(ctrl) {
      return ctrl.currentLevel === LEVELS.SCHOOL;
    }

    function isCurriculumReport(ctrl) {
      return ctrl.currentLevel === LEVELS.CURRICULUM;
    }

    function isTIAReport(ctrl) {
      return ctrl.isTIAReport;
    }

    function abbreviateNumber(num) {
      num = Number(num);
      if (num < 1000) {
        return '' + num;
      }
      var power = ((num).toPrecision(2).split("e")[1] || [0, 0]).slice(1);
      var triplets = Math.floor(power / 3);
      var numericResult = (num / Math.pow(10, triplets * 3)).toFixed(1);
      if (numericResult.length > 4) {
        numericResult = (num / Math.pow(10, triplets * 3)).toFixed();
      }
      return numericResult + ['', 'K', 'M', 'B', 'T'][triplets];
    }

    function getExportTimeOnTask(timeInSeconds) {
      var value = Math.round((timeInSeconds || 0) / 60);
      return value ? abbreviateNumber(value) : '';
    }

    function getTimeOnTask(timeInSeconds) {
      var value = Math.round((timeInSeconds || 0) / 60);
      return value ? abbreviateNumber(value) : '-';
    }

    function getFieldName(masterIndex, baseField) {
      return 'ex_' + masterIndex + '_' + baseField;
    }

    function renderAvgResp(val) {
      return val ? val : '-';
    }

    function renderQuizScore(val) {
      return (!val && val !== 0) ? '-' : val + '%';
    }

    function renderBreakouts(rec) {
      if (rec.breakouts_in_standard) {
        return rec.breakout_touched_count + '/' + rec.breakouts_in_standard;
      } else {
        return '';
      }
    }

    function calculateBreakoutsDonePercentage(rec) {
      if (rec.breakouts_in_standard && rec.breakout_touched_count) {
        return parseInt(rec.breakout_touched_count, 10) / parseInt(rec.breakouts_in_standard, 10);
      }
    }

    function getCustomElementBackgroundColor(columnName, value) {
      if (!columnName) {
        return;
      }
      var numValue = parseFloat(value);
      var percentage = 0;
      if (columnName.indexOf('number_of_breakouts') > -1) {
        if (!numValue) {
          return '#ffffff';
        }
        return 'rgba(170,170,170,' + (numValue) + ')';
      }
      if (columnName.indexOf('responses') > -1) {
        if (!numValue) {
          return '#ffffff';
        }
        return 'rgba(253, 180, 147,' + (numValue / 18) + ')';

      }
      if (columnName.indexOf('quiz') > -1) {
        if (isNaN(numValue)) {
          return '#ffffff';
        } else if (numValue > 49.5 && numValue < 50.5) {
          return '#ffffff';
        } else if (numValue < 50) {
          return 'rgba(230, 129, 114, ' + (1 - numValue * 0.02) + ')';
        } else if (numValue > 50) {
          return 'rgba(160, 223, 253, ' + (numValue * 0.02 - 1) + ')';
        }
      }
      if (columnName.indexOf('time_on_standard') > -1) {
        if (!numValue) {
          return '#ffffff';
        } else {
          // max value here is 60 minutes
          percentage = (numValue / (100 / 60));
          if (percentage > 99) {
            percentage = 100;
          }
          return 'rgba(245, 211, 40, ' + percentage * 0.01 + ')';
        }
      }
      return '';
    }

    function formatExperienceSummaryDataRow(ctrl, allRecs, expectationCodes, expectationsLabels, tiaTestScores) {
      var byKey = {};

      var keyColumn;
      if (isCurriculumReport(ctrl)) {
        keyColumn = 'student_user_id';
      }
      if (isDistrictReport(ctrl) || isSchoolReport(ctrl)) {
        keyColumn = 'class_id';
      }
      allRecs.forEach(function (rec) {
        var key = rec[keyColumn];
        if (!byKey[key]) {
          byKey[key] = [];
        }
        byKey[key].push(rec);
      });

      return Object.keys(byKey).map(function (keyId) {
        var recs = byKey[keyId];
        var firstRec = recs[0];

        var row = {};

        if (isCurriculumReport(ctrl)) {
          row.studentName = firstRec.student_first_name + ' ' + firstRec.student_last_name;
        }
        if (isDistrictReport(ctrl)) {
          row.schoolName = firstRec.school_name;
        }
        if (isDistrictReport(ctrl) || isSchoolReport(ctrl)) {
          row.teacherName = firstRec.teacher_first_name + ' ' + firstRec.teacher_last_name;
          row.teacherId = firstRec.teacher_user_id;
          row.className = firstRec.class_name;
          row.classId = firstRec.class_id;
          row.archivedInfo = firstRec.archived_info || false;
        }

        if (tiaTestScores) {
          let pretestStudent = tiaTestScores.find(function (score) {
            return score.id == keyId && score.pretest_tia;
          });
          if (pretestStudent) {
            row.preTest = renderQuizScore(pretestStudent.score);
            if (pretestStudent.growthTarget) {
              row.growthTarget = renderQuizScore(pretestStudent.growthTarget);
            } else {
              row.growthTarget = "N/A";
            }
          }
          let posttestStudent = tiaTestScores.find(function (score) {
            return score.id == keyId && score.posttest_tia;
          });
          if (posttestStudent) {
            row.postTest = renderQuizScore(posttestStudent.score);
          }
        }

        expectationCodes.forEach(function (code) {
          var codeRecs = recs.filter(function (r) {
            return r.short_expectation_code === code;
          });

          row.expectation = expectationsLabels[code];

          codeRecs.forEach(function (rec) {
            if (rec.pretest) {
              row[getFieldName(code, 'pretest')] = renderQuizScore(rec.percentage_correct);
              row[getFieldName(code, 'pretest') + '_bg'] = getCustomElementBackgroundColor('quiz', rec.percentage_correct);
            } else if (rec.posttest) {
              row[getFieldName(code, 'posttest')] = renderQuizScore(rec.percentage_correct);
              row[getFieldName(code, 'posttest') + '_bg'] = getCustomElementBackgroundColor('quiz', rec.percentage_correct);
            } else {
              row[getFieldName(code, 'number_of_breakouts')] = renderBreakouts(rec);
              row[getFieldName(code, 'number_of_breakouts') + '_bg'] = getCustomElementBackgroundColor('number_of_breakouts', calculateBreakoutsDonePercentage(rec));

              row[getFieldName(code, 'quiz')] = renderQuizScore(rec.avg_quiz_item_score);
              row[getFieldName(code, 'quiz') + '_bg'] = getCustomElementBackgroundColor('quiz', rec.avg_quiz_item_score);

              row[getFieldName(code, 'responses')] = renderAvgResp(rec.responses);
              row[getFieldName(code, 'responses') + '_bg'] = getCustomElementBackgroundColor('responses', rec.responses);

              row[getFieldName(code, 'time_on_standard')] = getTimeOnTask(rec.time_on_standard);
              row[getFieldName(code, 'time_on_standard') + '_bg'] = getCustomElementBackgroundColor('time_on_standard', getTimeOnTask(rec.time_on_standard));
            }
          });
        });
        return row;
      });
    }

    function setColumns(ctrl, standardExpectations, filterColumn, expectationLabels) {
      var firstColumns = [];
      if (isCurriculumReport(ctrl)) {
        firstColumns.push(
          {
            field: 'studentName',
            title: 'Student',
            titleName: 'Student',
            filter: {studentName: 'text'},
            sortable: 'studentName',
            class: 'xp-report-medium-cell'
          }
        );
      }
      if (isDistrictReport(ctrl)) {
        firstColumns.push(
          {
            field: 'schoolName',
            title: 'School',
            titleName: 'School',
            filter: {schoolName: 'text'},
            sortable: 'schoolName',
            class: 'xp-report-medium-cell'
          }
        );
        firstColumns.push(
          {
            field: 'teacherName',
            title: 'Teacher',
            titleName: 'Teacher',
            filter: {teacherName: 'text'},
            sortable: 'teacherName',
            class: 'xp-report-medium-cell'
          }
        );
        firstColumns.push(
          {
            field: 'className',
            title: 'Class',
            titleName: 'Class',
            filter: {className: 'text'},
            sortable: 'className',
            class: 'xp-report-medium-cell'
          }
        );
      }
      if (isTIAReport(ctrl)) {
        firstColumns.push(
          {
            field: 'preTest',
            title: 'Pre Test',
            titleName: 'Pretest',
            sortable: false,
            class: 'text-center xp-report-tiny-cell'
          }
        );


/*
        firstColumns.push(
          {
            field: 'growthTarget',
            title: 'Growth Target',
            titleName: 'GrowthTarget',
            sortable: false,
            class: 'text-center xp-report-tiny-cell'
          }
        );
        firstColumns.push(
          {
            field: 'avgScore',
            title: 'Avg Score',
            titleName: 'avgScore',
            sortable: false,
            class: 'text-center xp-report-tiny-cell'
          }
        );
        firstColumns.push(
          {
            field: 'postTest',
            title: 'Post Test',
            titleName: 'PostTest',
            sortable: false,
            class: 'text-center xp-report-tiny-cell'
          }
        );
*/



      }
      var columns = [];
      standardExpectations.forEach(function (code) {
        if (filterColumn === 'number_of_breakouts') {
          columns.push({
            field: getFieldName(code, 'number_of_breakouts'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
        if (filterColumn === 'responses') {
          columns.push({
            field: getFieldName(code, 'responses'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
        if (filterColumn === 'quiz') {
          columns.push({
            field: getFieldName(code, 'quiz'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
        if (filterColumn == 'pretest') {
          columns.push({
            field: getFieldName(code, 'pretest'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
        if (filterColumn == 'posttest') {
          columns.push({
            field: getFieldName(code, 'posttest'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
        if (filterColumn === 'time_on_standard') {
          columns.push({
            field: getFieldName(code, 'time_on_standard'),
            titleName: code,
            class: 'text-center xp-report-tiny-cell',
            title: code,
            headerTitle: expectationLabels[code],
            sortable: false
          });
        }
      });

      ctrl.columns = firstColumns.concat(columns);
    }

    function renderReport(ctrl, standardExpectations, filterColumn, data) {
      setColumns(ctrl, standardExpectations, filterColumn, ctrl.standardExpectationLabels);
      ctrl.tableParams.settings({
        dataset: data
      });
    }

    function calculateExpectationCodes(data, typeId, conceptId) {
      if (typeId && conceptId) {
        return data[conceptId + ':' + typeId].codes;
      } else {
        return data.map(function (exp) {
          return exp.short_expectation_code;
        });
      }
    }

    function calculateExpectationLabels(data, typeId, conceptId) {
      if (typeId && conceptId) {
        return data[conceptId + ':' + typeId].expectations;
      } else {
        let labels = [];
        data.forEach(function (exp) {
          labels[exp.expectation_code] = exp.expectation_label;
        });
        return labels;
      }
    }

    function generateUniqueClassList(data) {
      var idToName = {};
      data.forEach(function (rec) {
        idToName[rec.class_id] = rec.class_name;
      });
      var uniqueClasses = Object.keys(idToName).map(function (id) {
        var classRec = data.find(function (rec) {
          return rec.class_id === parseInt(id, 10);
        });
        return {
          id: classRec.class_id,
          name: classRec.class_name,
          status: classRec.class_status
        };
      })
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      var active = uniqueClasses.filter(function (cl) {
        return cl.status.toUpperCase() === 'ACTIVE';
      });
      var inActive = uniqueClasses.filter(function (cl) {
        return cl.status.toUpperCase() !== 'ACTIVE';
      });
      if (inActive.length === 0) {
        return active;
      }
      return active.concat({id: 'none', name: '', status: 'DIVIDER'}).concat(inActive);
    }

    function generateUniqueSchoolList(data) {
      var idToName = {};
      data.forEach(function (rec) {
        idToName[rec.school_id] = rec.school_name;
      });
      var uniqueSchools = Object.keys(idToName).map(function (id) {
        var schoolRec = data.find(function (rec) {
          return rec.school_id === parseInt(id, 10);
        });
        return {
          id: schoolRec.school_id,
          name: schoolRec.school_name
        };
      })
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      return uniqueSchools;
    }

    function generateUniqueTeacherList(data) {
      var idToName = {};
      data.forEach(function (rec) {
        idToName[rec.teacher_user_id] = rec.teacher_first_name + " " + rec.teacher_last_name;
      });
      var uniqueTeachers = Object.keys(idToName).map(function (id) {
        var teacherRec = data.find(function (rec) {
          return rec.teacher_user_id === parseInt(id, 10);
        });
        return {
          id: teacherRec.teacher_user_id,
          name: teacherRec.teacher_first_name + " " + teacherRec.teacher_last_name,
          schoolId: teacherRec.school_id
        };
      })
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      return uniqueTeachers;
    }

    function generateUniqueTypeConceptFunction(filteredColumn) {
      return function getUniqueByColumn(data) {
        var columnId = filteredColumn + '_id';
        var columnName = filteredColumn + '_label';
        var idToName = {};
        Object.keys(data).forEach(function (key) {
          idToName[data[key][columnId]] = data[key][columnName];
        });
        return Object.keys(idToName)
          .sort(function (a, b) {
            return a.localeCompare(b, 'en', {numeric: true});
          })
          .map(function (id) {
            return {
              id: parseInt(id, 10) || 0,
              name: idToName[id]
            };
          });
      };
    }

    function getReportFilter(ctrl) {
      if (isCurriculumReport(ctrl)) {
        return function (rec) {
          return (
            rec.class_id === ctrl.filterClass.id &&
            (!ctrl.filterType.id || rec.type_id === ctrl.filterType.id) &&
            (!ctrl.filterConcept.id || rec.concept_id === ctrl.filterConcept.id)
          );
        };
      } else if (isDistrictReport(ctrl)) {
        return function (rec) {
          return (
            (!ctrl.filterSchool || ctrl.filterSchool.id == 'none' || rec.school_id === ctrl.filterSchool.id) &&
            (!ctrl.filterTeacher || ctrl.filterTeacher.id == 'none' || rec.teacher_user_id === ctrl.filterTeacher.id) &&
            (!ctrl.filterType.id || rec.type_id === ctrl.filterType.id) &&
            (!ctrl.filterConcept.id || rec.concept_id === ctrl.filterConcept.id)
          );
        };
      }
      return function (rec) {
        return (
          (!ctrl.filterType.id || rec.type_id === ctrl.filterType.id) &&
          (!ctrl.filterConcept.id || rec.concept_id === ctrl.filterConcept.id)
        );
      };
    }

    function getStyleClass(staticColumns, columnName) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return 'xp-report-medium-cell';
      } else {
        return 'xp-report-small-box';
      }
    };

    function getTitlePopup(staticColumns, columnName, data, columnTitle) {
      if (!staticColumns || !columnName) {
        return;
      }
      if (staticColumns.indexOf(columnName) > -1) {
        return columnTitle + ': ' + data;
      } else {
        return columnTitle;
      }
    };

    function setSchool(ctrl, selection) {
      if (!selection || !selection.id) {
        ctrl.filterSchool = false;
        return false;
      }
      ctrl.filterSchool = selection;
      return updateReport(ctrl);
    };

    function setTeacher(ctrl, selection) {
      if (!selection || !selection.id) {
        ctrl.filterTeacher = false;
        return false;
      }
      ctrl.filterTeacher = selection;
      return updateReport(ctrl);
    };

    function setClass(ctrl, selection) {
      if (!selection || !selection.id) {
        ctrl.filterClass = false;
        return false;
      }
      if (selection.id === 'none') {
        return false;
      }
      ctrl.filterClass = selection;
      return updateReport(ctrl);
    };

    function setMetric(ctrl, selection) {
      ctrl.filterMetricName = selection.name;
      ctrl.filterMetric = selection.id;
      setColumns(ctrl, ctrl.standardExpectations, selection.id, ctrl.standardExpectationLabels);
    };

    function updateReport(ctrl) {
      var reportData = (ctrl.reportData || []).filter(getReportFilter(ctrl));
      ctrl.standardExpectations = calculateExpectationCodes(ctrl.expectationCodes, ctrl.filterType.id, ctrl.filterConcept.id);
      ctrl.standardExpectationLabels = calculateExpectationLabels(ctrl.expectationCodes, ctrl.filterType.id, ctrl.filterConcept.id);
      ctrl.data = formatExperienceSummaryDataRow(ctrl, reportData, ctrl.standardExpectations, ctrl.standardExpectationLabels, ctrl.tiaTestScores);
      renderReport(ctrl, ctrl.standardExpectations, ctrl.filterMetric, ctrl.data);
    }

    function exportColumns(ctrl) {
      var csvColumns = [];
      if (isDistrictReport(ctrl)) {
        csvColumns.push(
          {
            name: 'Campus',
            field: 'schoolName',
            wrap: true
          }
        );
        csvColumns.push(
          {
            name: 'Teacher Name',
            field: 'prettyName',
            wrap: true
          }
        );
        csvColumns.push(
          {
            name: 'Teacher Email',
            field: 'teacherEmail',
            wrap: true
          }
        );
      }
      csvColumns.push(
        {
          name: 'Class Name',
          field: 'className',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Student Name',
          field: 'studentName',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Student Username',
          field: 'studentUsername',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Pre Test',
          field: 'preTest',
          wrap: true
        }
      );


/*
      csvColumns.push(
        {
          name: 'Growth Target',
          field: 'growthTarget',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Average Score',
          field: 'averageScore',
          wrap: true
        }
      );
      csvColumns.push(
        {
          name: 'Post Test',
          field: 'postTest',
          wrap: true
        }
      );
*/


      ctrl.standardExpectations.forEach(function (expectation) {
        csvColumns.push({
          name: expectation,
          field: expectation,
          wrap: true
        });
      });

      return csvColumns;
    }

    function newDataRow(row, tiaScores) {
      var rec = {
        schoolName: row.school_name,
        prettyName: row.teacher_first_name + ' ' + row.teacher_last_name,
        teacherEmail: row.teacher_email,
        className: row.class_name,
        studentName: row.student_first_name + ' ' + row.student_last_name,
        studentUsername: row.student_username
      };
      let studentPretest = tiaScores.find(function (score) {
        return score.id === row.student_user_id && score.pretest_tia && score.cid === row.class_id;
      });
      if (studentPretest) {
        rec.preTest = studentPretest.score;
        rec.growthTarget = studentPretest.growthTarget;
      }
      let studentPosttest = tiaScores.find(function (score) {
        return score.id === row.student_user_id && score.posttest_tia && score.cid === row.class_id;
      });
      if (studentPosttest) {
        rec.postTest = studentPosttest.score;
      }
      return rec;
    }

    function sortExportData(a, b) {
      if (a.school_name) {
        let schoolCompare = a.school_name.localeCompare(b.class_name);
        if (schoolCompare != 0) {
          return schoolCompare;
        }
      }
      if (a.teacher_last_name) {
        let teacherLastNameCompare = a.teacher_last_name.localeCompare(b.teacher_last_name);
        if (teacherLastNameCompare != 0) {
          return teacherLastNameCompare;
        }
        let teacherFirstNameCompare = a.teacher_first_name.localeCompare(b.teacher_first_name);
        if (teacherFirstNameCompare != 0) {
          return teacherFirstNameCompare;
        }
      }
      let classCompare = a.class_name.localeCompare(b.class_name);
      if (classCompare != 0) {
        return classCompare;
      }
      let studentLastNameCompare = a.student_last_name.localeCompare(b.student_last_name);
      if (studentLastNameCompare != 0) {
        return studentLastNameCompare;
      }
      let studentFirstNameCompare = a.student_first_name.localeCompare(b.student_first_name);
      if (studentFirstNameCompare != 0) {
        return studentFirstNameCompare;
      }
      return 0;
    }

    function formatExportData(reportData, tiaScores, metric) {
      let data = reportData.filter(function (record) {
        return (metric == 'pretest' && record.pretest) ||
          (metric == 'posttest' && record.pretest) ||
          (metric != 'pretest' && !record.pretest && metric != 'posttest' && !record.posttest);
      }).sort(sortExportData);
      let currentClassId = 0;
      let currentStudentId = 0;
      let rows = [];
      let currentRow = null;
      data.forEach(function (record) {
        if (currentClassId != record.class_id || currentStudentId != record.student_user_id) {
          if (currentRow) {
            rows.push(currentRow);
          }
          currentRow = newDataRow(record, tiaScores);
          currentClassId = record.class_id;
          currentStudentId = record.student_user_id;
        }
        if (metric == 'pretest' || metric == 'posttest') {
          currentRow[record.short_expectation_code] = Math.round(record.percentage_correct);
        } else if (metric == 'quiz') {
          currentRow[record.short_expectation_code] = record.avg_quiz_item_score;
        } else if (metric == 'responses') {
          currentRow[record.short_expectation_code] = record.responses;
        } else if (metric == 'time_on_standard') {
          currentRow[record.short_expectation_code] = getExportTimeOnTask(record.time_on_standard);
        }
      });
      if (currentRow) {
        rows.push(currentRow);
      }
      return rows;
    }

    function exportData(ctrl, rawExportData, tiaTestScores) {
      let data = rawExportData ? rawExportData : ctrl.reportData;
      let tiaScores = tiaTestScores ? tiaTestScores : ctrl.tiaTestScores;
      let exportData = formatExportData(data, tiaScores, ctrl.filterMetric).filter(function(row) {
        return row.preTest;
      });
      var csvRows = [];
      for (var i = 0; i < exportData.length; i++) {
        csvRows.push(exportData[i]);
      }
      return csvRows;
    }

    function exportFile(ctrl) {
      return (ctrl.parent.title || 'TIA') + ' ' + ctrl.filterMetricName + '.csv';
    }

    return {
      LEVELS: LEVELS,
      isDistrictReport: isDistrictReport,
      isSchoolReport: isSchoolReport,
      isCurriculumReport: isCurriculumReport,
      generateUniqueClassList: generateUniqueClassList,
      generateUniqueSchoolList: generateUniqueSchoolList,
      generateUniqueTeacherList: generateUniqueTeacherList,
      generateUniqueTypeConceptFunction: generateUniqueTypeConceptFunction,
      updateReport: updateReport,
      getStyleClass: getStyleClass,
      getTitlePopup: getTitlePopup,
      setSchool: setSchool,
      setTeacher: setTeacher,
      setClass: setClass,
      setMetric: setMetric,
      setColumns: setColumns,
      exportColumns: exportColumns,
      exportData: exportData,
      exportFile: exportFile
    };
  }]);
